const user = (axios) => {
    return {
        state: {
            users: []
        },
        mutations: {
            setUsers(state, payload) {
                state.users = payload;
            }
        },
        actions: {
            async fetchUserById({ commit }, id) {
                try {
                    const response = await axios.get(`/master/user/id/${id}`).then(http => http.data)
                    return new Promise(resolve => {
                        const { data } = response
                        //console.log(`data ::== ${JSON.stringify(data)}`)
                        //commit('setElder', data)
                        resolve(response)
                    })
                } catch (error) {
                    return Promise.reject(error);
                }
            },
            async fetchUsers({ commit }) {
                try {
                    const response = await axios.get(`/master/user/all`).then(http => http.data)
                    return new Promise(resolve => {
                        const { data } = response
                        //console.log(`data ::== ${JSON.stringify(data)}`)
                        commit('setUsers', data);
                        resolve(response)
                    })
                } catch (error) {
                    return Promise.reject(error);
                }
            },
            async fetchUsersOrderFname({ commit }, payload) {
                try {
                    const response = await axios.get(`/master/user/all/order/fname`, {
                        params: payload
                    }).then(http => http.data)
                    return new Promise(resolve => {
                        const { data } = response
                        //console.log(`data ::== ${JSON.stringify(data)}`)
                        commit('setUsers', data);
                        resolve(response)
                    })
                } catch (error) {
                    return Promise.reject(error);
                }
            },
            async saveUser({ commit, dispatch }, payload) {
                try {
                    console.log('payload ::==' + JSON.stringify(payload))
                    const response = await axios.post(`/master/user/save`, payload).then(http => http.data)
                    return new Promise(resolve => {
                        //dispatch('fetchUsers')
                        resolve(response)
                    })
                } catch (error) {
                    return Promise.reject(error);
                }
            },
            async deleteUser({ commit, dispatch, rootGetters }, id) {
                try {

                    const response = await axios.post(`/master/user/delete/id/${id}`).then(http => http.data)
                    return new Promise(resolve => {
                        const {
                            codeId: userId,
                            userLevel: userLevel,
                            area: userArea
                        } = rootGetters['oauth/session']
                        const payload = {
                            userLevel: userLevel,
                            areaId: userArea?.codeId
                        }
                        dispatch('fetchUsersOrderFname', payload)
                        resolve(response)
                    })
                } catch (error) {
                    return Promise.reject(error);
                }
            },
            async updateUserProfile({ commit, dispatch }, payload) {
                try {
                    /*
                      {
                          "userLevel": "GUEST",
                          "areaId": 0,
                          "fname": "system-fname",
                          "lname": "system-lname",
                          "mobile": "080000000",
                          "tel": "080000000",
                          "email": "poom@gmail.com",
                          "userName": "poom",
                          "userPassword": "poom",
                          "userStatus": "active",
                          "lastLoginDtm": "2021-03-05T14:49:06.995308",
                          "failAttempt": 0,
                          "createDtm": "2021-03-05",
                          "createBy": 0
                      }
                  */
                    const { userName } = payload
                    const response = await axios.post(`/user/update/profile/username/${userName}`, payload).then(http => http.data)
                    return new Promise(resolve => {
                        resolve(response)
                    })
                } catch (error) {
                    return Promise.reject(error);
                }
            },
            async updateUserPassword({ commit, dispatch }, payload) {
                try {
                    /*
                    {
                        "userName" : "system",
                        "userPasswordOld" : "systemsystem",
                        "userPasswordNew" : "system"
                    }
                    */
                    const { userName } = payload
                    const response = await axios.post(`/user/update/password/username/${userName}`, payload).then(http => http.data)
                    return new Promise(resolve => {
                        resolve(response)
                    })
                } catch (error) {
                    return Promise.reject(error);
                }
            },
            async sendMailForgotPassword({ commit, dispatch }, payload) {
                try {
                    /*
                    {
                        "secureEmail" : "system",
                    }
                    */
                    const { userName } = payload
                    const response = await axios({
                        url: `/anonymous/send/forgotemail/username/${userName}`,
                        method: 'POST',
                        data: payload,
                        transformRequest: (data, headers) => {
                            delete headers.common['Authorization'];
                            return JSON.stringify(data);
                        }
                    }).then(http => http.data)
                    return new Promise(resolve => {
                        resolve(response)
                    })
                } catch (error) {
                    return Promise.reject(error);
                }
            },
            async resetPasswordFromMail({ commit, dispatch }, payload) {
                try {
                    /*
                    {
                        "userPasswordNew" : "****",
                        "userPasswordNewConf" : "****"
                    }
                    */
                    const { secureToken } = payload
                    ///reset/password/token/{token}
                    const response = await axios({
                        url: `/anonymous/reset/password/token/${secureToken}`,
                        method: 'POST',
                        data: payload,
                        transformRequest: (data, headers) => {
                            delete headers.common['Authorization'];
                            return JSON.stringify(data);
                        }
                    }).then(http => http.data)
                    return new Promise(resolve => {
                        resolve(response)
                    })
                } catch (error) {
                    return Promise.reject(error);
                }
            }
        },
        namespaced: true,
    }
}

export default user;