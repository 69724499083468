const elderly = (axios) => {
    return {
        state: {
            elderlies: [],
            elder: {},
        },
        mutations: {
            setElderlies(state, payload) {
                state.elderlies = payload
            },
            setElder(state, payload) {
                state.elder = payload
            }
        },
        actions: {
            async fetchElderById({ commit }, id) {
                try {
                    const response = await axios.get(`/master/elderPerson/id/${id}`).then(http => http.data)
                    return new Promise(resolve => {
                        const { data } = response
                        //console.log(`data ::== ${JSON.stringify(data)}`)
                        //commit('setElder', data)
                        resolve(response)
                    })
                } catch (error) {
                    return Promise.reject(error);
                }
            },
            async fetchElderlies({ commit }) {
                try {
                    const response = await axios.get('/master/elderPerson/all').then(http => http.data)
                    return new Promise(resolve => {
                        const { data } = response
                        commit('setElderlies', data)
                        resolve(response)
                    })
                } catch (error) {
                    return Promise.reject(error);
                }
            },
            async fetchElderliesOrderMappingCode({ commit }, payload) {
                try {
                    const response = await axios.get('/master/elderPerson/all/order/mappingCode', {
                        params: payload
                    }).then(http => http.data)
                    return new Promise(resolve => {
                        const { data } = response
                        commit('setElderlies', data)
                        resolve(response)
                    })
                } catch (error) {
                    return Promise.reject(error);
                }
            },
            async saveElder({ commit, dispatch }, payload) {
                try {
                    const response = await axios.post(`/master/elderPerson/save`, payload).then(http => http.data)
                    return new Promise(resolve => {
                        //dispatch('fetchElderlies')
                        resolve(response)
                    })
                } catch (error) {
                    return Promise.reject(error);
                }
            },
            async deleteElder({ commit, dispatch }, id) {
                try {
                    const response = await axios.post(`/master/elderPerson/id/${id}`).then(http => http.data)
                    return new Promise(resolve => {
                        //dispatch('fetchElderlies')
                        resolve(response)
                    })
                } catch (error) {
                    return Promise.reject(error);
                }
            },
            async uploadFileElderlyHealth({ commit, dispatch }, payload) {
                try {

                    const response = await axios({
                        url: `/master/elderPerson/import/nonhealth`,
                        method: 'post',
                        headers: {
                            'content-type': 'application/x-www-form-urlencoded'
                        },
                        // timeout: 500,
                        data: payload
                    }).then(http => http.data)
                    return new Promise(resolve => {
                        resolve(response)
                    })
                } catch (error) {
                    return Promise.reject(error);
                }
            },
            async uploadFileHealth({ commit, dispatch }, {payload, tableDropdown}) {
                try {
                    const response = await axios({
                        url: `/master/elderPerson/import/`+tableDropdown,
                        method: 'post',
                        headers: {
                            'content-type': 'application/x-www-form-urlencoded'
                        },
                        // timeout: 500,
                        data: payload
                    }).then(http => http.data)
                    return new Promise(resolve => {
                        resolve(response)
                    })
                } catch (error) {
                    return Promise.reject(error);
                }
            }
        },
        namespaced: true,
    }
}

export default elderly;