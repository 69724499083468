<template>
  <router-view />
</template>

<style lang="scss" scoped>
@import "@/assets/layout/layout.scss";
@import "@/assets/layout/sass/_dashboard.scss";
@import "@/assets/scss/style.scss";

:global(*:not(i):not(.pi)),
:global(.p-placeholder) {
  font-family: "Prompt-Regular", serif !important;
}

:global(::-webkit-input-placeholder, ::-moz-placeholder, :-ms-input-placeholder, :-moz-placeholder) {
  font-family: "Prompt-Regular", serif !important;
}

@media screen and (max-width: 360px) {
  :global(.p-dialog-responsive) {
    width: 90vw !important;
  }
}
:global(.p-inline-message-error) {
  width: 80px;
  height: 20px;
  :deep(span) {
    color: red;
  }
}
</style>
