const area = (axios) => {
    return {
        state: {
            areas: []
        },
        mutations: {
            setAreas(state, payload) {
                state.areas = payload;
            }
        },
        actions: {
            async fetchAreaById({ commit }, id) {
                try {
                    const response = await axios.get(`/master/area/id/${id}`).then(http => http.data)
                    return new Promise(resolve => {
                        const { data } = response
                        //console.log(`data ::== ${JSON.stringify(data)}`)
                        //commit('setElder', data)
                        resolve(response)
                    })
                } catch (error) {
                    return Promise.reject(error);
                }
            },
            async fetchAreasOrderNameTh({ commit }) {
                try {
                    const response = await axios.get(`/master/area/all/order/nameTh`).then(http => http.data)
                    return new Promise(resolve => {
                        const { data } = response
                        //console.log(`data ::== ${JSON.stringify(data)}`)
                        commit('setAreas', data);
                        resolve(response)
                    })
                } catch (error) {
                    return Promise.reject(error);
                }
            },
            async saveArea({ commit, dispatch }, payload) {
                try {
                    const response = await axios.post(`/master/area/save`, payload).then(http => http.data)
                    return new Promise(resolve => {
                        dispatch('fetchAreasOrderNameTh')
                        resolve(response)
                    })
                } catch (error) {
                    return Promise.reject(error);
                }
            },
            async deleteArea({ commit, dispatch }, id) {
                try {
                    const response = await axios.post(`/master/area/delete/id/${id}`).then(http => http.data)
                    return new Promise(resolve => {
                        dispatch('fetchAreasOrderNameTh')
                        resolve(response)
                    })
                } catch (error) {
                    return Promise.reject(error);
                }
            }
        },
        getters: {
            getAreasHasAllLabel(state) {
                return [
                    {
                        areaName: 'ทั้งหมด',
                        codeId: 'ALL'
                    },
                    ...state.areas
                ]
            }
        },
        namespaced: true,
    }
}

export default area;