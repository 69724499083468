import * as moment from "moment";
import { padStart } from "lodash";

const bdDays = () => {
    return Array.from({ length: 31 }, (v, k) => k + 1).map((v) => ({
        text: v,
        value: padStart(v, 2, "0"),
    }));
}
const bdMonths = (primevue) => {
    //const { monthNames } = this.$primevue.config.locale;
    const { monthNames } = primevue.config.locale;
    //console.log("monthNames ::==" + monthNames);
    return monthNames.map((v, k) => ({
        text: v,
        value: padStart(k + 1, 2, "0"),
    }));
}
const bdYears = () => {
    const begin = 543;
    return Array.from({ length: 100 }, (v, k) => {
        return moment().add(begin, "years").subtract(k, "years").format("YYYY");
    }).map((v) => ({
        text: v,
        value: v,
    }));
}

const levels = {
    AC: "เจ้าหน้าที่ มูลนิธิสดศรี (AC)",
    OL: "เจ้าหน้าที่ องค์กรปกครองส่วนท้องถิ่น/โรงพยาบาลส่งเสริมสุขภาพตำบล (รพ.สต.) (OL)",
    AL: "ผู้บริหาร องค์กรปกครองส่วนท้องถิ่น/ โรงพยาบาลส่งเสริมสุขภาพตำบล (รพ.สต.) (AL)"
}
/*
[
            { name: "เจ้าหน้าที่ มูลนิธิสดศรี (AC)", code: "AC" },
            { name: "เจ้าหน้าที่ องค์กรปกครองส่วนท้องถิ่น (OL)", code: "OL" },
            { name: "ผู้บริหาร องค์กรปกครองส่วนท้องถิ่น (AL)", code: "AL" },
        ]*/

const areas = {
    '01': 'อปท. แม่เงิน จ.เชียงราย',
    '02': 'อปท. ศรีสองรัก จ.เลย',
    '03': 'อปท. ศรีราชา จ.ชลบุรี',
    '04': 'อปท. ท่าแพ จ.สตูล',
    '05': 'นักวิจัย',
}

const partners = {
    '01': 'องค์กรปกครองส่วนท้องถิ่น',
    '02': 'ชมรมผู้สูงอายุ/กลุ่มตัวแทนผู้สูงอายุ',
    '03': 'ผู้สูงอายุ',
    '04': 'พัฒนาชุมชนอำเภอ(พชอ.)',
    '05': 'โรงพยาบาล/รพ.สต./หมอ/พยาบาล',
    '06': 'กศน.',
    '07': 'อบจ.',
    '08': 'สนง.เกษตรจังหวัด',
    '09': 'สนง.ประมงจังหวัด',
    '10': 'สปสช.',
    '11': 'พมจ.',
    '12': 'แรงงานจังหวัด',
    '13': 'ภาคประชาคม',
    '14': 'อื่นๆ'
}

const constance = {
    state: {
        levels: Object.keys(levels).map((lev, i) => {
            return { name: levels[lev], code: lev }
        }),
        levels_: levels,
        days: bdDays(),
        months: [],
        years: bdYears(),
        areas,
        partners
    },
    mutations: {
        initialDate(state, payload) {
            const { primevue } = payload
            // state.days = bdDays();
            state.months = bdMonths(primevue);
            // state.years = bdYears()
        }
    },
    actions: {

    },
    namespaced: true,
}
export default constance;