

const toFormat = (valueInt) => {
    console.log('valueInt ::==', valueInt)
    if (valueInt) {
        return valueInt.toLocaleString('th-TH', {
            maximumFractionDigits: 0
        });
    } else {
        return 0;
    }
}

const statistics = (axios) => {
    return {
        state: {
            statistics: {
                elder: 0,
                career: 0,
                area: 0,
                elderArea: 0
            },
        },
        mutations: {
            setStatistics(state, payload) {
                state.statistics = payload
            }
        },
        actions: {
            async fecthStatisticsState({ commit }, payload) {
                try {
                    const response = await axios({
                        url: `/anonymous/statistics/dashboard`,
                        method: 'GET',
                        transformRequest: (data, headers) => {
                            delete headers.common['Authorization'];
                            return JSON.stringify(data);
                        },
                        params: payload
                    }).then(http => http.data)
                    return new Promise(resolve => {
                        const { data } = response
                        console.log(`data ::== ${JSON.stringify(data)}`)
                        commit('setStatistics', data)
                        resolve(response)
                    })
                } catch (error) {
                    return Promise.reject(error);
                }
            },
        },
        getters: {
            toFormat: state => {
                const { elder,
                    career,
                    area,
                    elderArea, } = state.statistics
                return {
                    elder: toFormat(elder),
                    career: toFormat(career),
                    //area: toFormat(area),
                    area: 3,
                    elderArea: toFormat(elderArea),
                }
            }
        },
        namespaced: true,
    }
}
export default statistics;