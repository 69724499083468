const dialog = {
    state: {
        confirmDelete: false
    },
    mutations: {
        setConfirmDelete(state, isOpen) {
            state.confirmDelete = isOpen
        }
    },
    actions: {

    },
    namespaced: true,
}
export default dialog;