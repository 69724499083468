const menu = () => {
    return {
        state: {

        },
        mutations: {

        },
        actions: {

        },
        namespaced: true,
    }
}

export default menu;