<template>
  <div class="p-error required">{{ text }}</div>
</template>

<script>
export default {
  name: "required",
  props: {
    text: {
      type: String,
      required: false,
      default: () => "",
    },
  },
};
</script>

<style lang="scss" scoped>
.required {
  font-size: 12px;
  color: red;
  font-weight: bold;
  background-color: #f0b74ef5;
  padding: 1px;
  border-radius: 5px;
}
</style>