import axios from 'axios'


/*for (const key in process.env) {
    if (Object.hasOwnProperty.call(process.env, key)) {
        const element = process.env[key];
        //console.log('key ::== '+key+' value ::==',element)
    }
}*/
const { VUE_APP_API_ENDPOINT } = process.env


const Instance = (router, toast) => {
    const instance = axios.create({
        baseURL: VUE_APP_API_ENDPOINT, //'http://localhost:8081/v1',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
        timeout: 1000 * 30, // Wait for 30 seconds
    });

    const { VUE_APP_AUTH_MODE } = process.env

    instance.interceptors.request.use(

        config => {
            const token = localStorage.getItem("jwt");
            if (token && VUE_APP_AUTH_MODE == 'true') {
                config.headers.common["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        error => {
            return Promise.reject(error);
        }
    );
    instance.interceptors.response.use(
        response => {

            if (response.status === 200 || response.status === 201) {
                return Promise.resolve(response);
            } else {
                return Promise.reject(response);
            }
        },
        error => {

            console.log('error.response ::==', JSON.stringify(error))

            const { status } = error.response || { status: '000' }
            if ([401, 403].includes(status) && VUE_APP_AUTH_MODE === 'true') {
                const { _value } = router.currentRoute
                console.log('_value ::==', _value)
                const { fullPath, hash, query, name, path } = _value
                if (!['Login', 'Logout'].includes(name)) {
                    alert('หมดเวลาการเข้าใช้งาน กรุณาเข้าระบบอีกครั้ง');
                    setTimeout(() => {
                        router.replace({
                            path: "/login",
                            query: { redirect: router.currentRoute.fullPath }
                        });
                    }, 1000)
                }
            }
            return Promise.reject(error);
        }
    );
    return instance;
}
export default Instance;