import { Trades, Rubbers, Orcards, Rices, Farms, mapCamelKeyToSneckKey } from './decision'

const prediction = {
    state: {
        labelsValue: {
            pain_score: null,
            health_sum: null,
            sum_tmse: null,
            sum_sts: null,
            disease_total: null
        },
        isModals: {
            pain_score: false,
            health_sum: false,
            sum_tmse: false,
            sum_sts: false,
            disease_total: false
        },
        labelsCareer: {
            career_need_1: mapCamelKeyToSneckKey(Trades),
            career_need_2: mapCamelKeyToSneckKey(Farms),
            career_need_3: mapCamelKeyToSneckKey(Rubbers),
            career_need_4: mapCamelKeyToSneckKey(Orcards),
            career_need_5: mapCamelKeyToSneckKey(Rices),
        }
    },
    mutations: {
        setModal(state, { modalName, isOpen }) {
            console.log('modalName ::==' + modalName)
            console.log('isOpen ::==' + isOpen)
            state.isModals[modalName] = isOpen
        },
        setLabelValue(state, { labelName, value }) {
            state.labelsValue[labelName] = value ? value : 0
        },
        setLabelsCareer(state, { career, value }) {
            state.labelsCareer[career].forEach(val => {
                val.select = value
            })
        }
    },
    actions: {

    },
    getters: {
        //disease_total
        isModalPainScore(state) {
            return state.isModals['pain_score']
        },
        isModalHealthSum(state) {
            return state.isModals['health_sum']
        },
        isModalSumTmse(state) {
            return state.isModals['sum_tmse']
        },
        isModalSumSts(state) {
            return state.isModals['sum_sts']
        },
        isModalDiseaseTotal(state) {
            return state.isModals['disease_total']
        },
        values(state) {
            return state.labelsValue
        }
    },
    namespaced: true,
}

export default prediction