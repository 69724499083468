

const setRadioSelected = (valueFields, labelFields) => {
    // valueFields = {"painNeck" : "1","painElbow" : "1","painFoot" : "1","painHip" : "1",}
    // radioFields = ['painNeck','painNeck',....]
    let isSelected = false;
    const radios = labelFields.map(field => {
        const { name, topic, select } = field
        const value = valueFields[name]
        return { name, topic, select: value == null ? null : parseInt(value), };
    })
    //{ name: 'painNeck', topic: "1. คอ", select: null, },
    console.log('radios ::==', radios)
    return radios;
}





const entryTrain = (axios) => {
    //console.log('axios ::', axios)

    const section = {
        tablePain: [
            { name: 'pain_neck', topic: "1. คอ", select: null, },
            { name: 'pain_shoulder', topic: "2. ไหล่", select: null, },
            { name: 'pain_scapula', topic: "3. สะบัก", select: null, },
            { name: 'pain_back', topic: "4. หลังส่วนเอว", select: null, },
            { name: 'pain_hip', topic: "5. สะโพก/ต้นขา", select: null, },
            { name: 'pain_knee', topic: "6. ข้อเข่า/น่อง", select: null, },
            { name: 'pain_foot', topic: "7. ข้อเท้า/เท้า/นิ้ว", select: null, },
        ],
    }


    return {
        state: {
            entity: {
                ...section
            }
        },
        mutations: {
            setEntity(state, payload) {

                state.entity = {
                    tablePain: setRadioSelected(payload, section.tablePain),
                    ...payload
                };
            },
            setEntryFieldByKey(state, payload) {
                Object.keys(payload).forEach(key => {
                    //console.log('key ::==', key)
                    state.entity[key] = payload[key]
                })
            },
            updateTablePain(state, careerValue) {
                let newTablePain = [];
                if (careerValue === "career_all") {
                    newTablePain = [
                        { name: 'pain_neck', topic: "1. คอ", select: null },
                        { name: 'pain_shoulder', topic: "2. ไหล่", select: null },
                        { name: 'pain_scapula', topic: "3. สะบัก", select: null },
                        { name: 'pain_back', topic: "4. หลังส่วนเอว", select: null },
                        { name: 'pain_hip', topic: "5. สะโพก/ต้นขา", select: null },
                        { name: 'pain_knee', topic: "6. ข้อเข่า/น่อง", select: null },
                        { name: 'pain_foot', topic: "7. ข้อเท้า/เท้า/นิ้ว", select: null },
                    ];
                } else if (careerValue === "career_need_1") {
                    newTablePain = [
                        { name: 'pain_neck', topic: "1. คอ", select: null },
                        { name: 'pain_shoulder', topic: "2. ไหล่", select: null },
                        { name: 'pain_scapula', topic: "3. สะบัก", select: null },
                        { name: 'pain_back', topic: "4. หลังส่วนเอว", select: null },
                        { name: 'pain_hip', topic: "5. สะโพก/ต้นขา", select: null },
                        { name: 'pain_knee', topic: "6. ข้อเข่า/น่อง", select: null }
                    ];
                } else if (careerValue === "career_need_2") {
                    newTablePain = [
                        { name: 'pain_neck', topic: "1. คอ", select: null },
                        { name: 'pain_shoulder', topic: "2. ไหล่", select: null },
                        { name: 'pain_scapula', topic: "3. สะบัก", select: null },
                        { name: 'pain_back', topic: "4. หลังส่วนเอว", select: null },
                        { name: 'pain_hip', topic: "5. สะโพก/ต้นขา", select: null },
                        { name: 'pain_knee', topic: "6. ข้อเข่า/น่อง", select: null },
                        { name: 'pain_foot', topic: "7. ข้อเท้า/เท้า/นิ้ว", select: null }
                    ];
                } else if (careerValue === "career_need_3") {
                    newTablePain = [
                        { name: 'pain_shoulder', topic: "1. ไหล่", select: null },
                        { name: 'pain_back', topic: "2. หลังส่วนเอว", select: null },
                        { name: 'pain_hip', topic: "3. สะโพก/ต้นขา", select: null },
                        { name: 'pain_knee', topic: "4. ข้อเข่า/น่อง", select: null },
                    ];
                } else if (careerValue === "career_need_4") {
                    newTablePain = [
                        { name: 'pain_neck', topic: "1. คอ", select: null },
                        { name: 'pain_shoulder', topic: "2. ไหล่", select: null },
                        { name: 'pain_hand', topic: "3. มือ", select: null },
                        { name: 'pain_back', topic: "4. หลังส่วนเอว", select: null },
                        { name: 'pain_hip', topic: "5. สะโพก/ต้นขา", select: null },
                        { name: 'pain_knee', topic: "6. ข้อเข่า/น่อง", select: null }
                    ];
                } else if (careerValue === "career_need_5") {
                    newTablePain = [
                        { name: 'pain_neck', topic: "1. คอ", select: null },
                        { name: 'pain_shoulder', topic: "2. ไหล่", select: null },
                        { name: 'pain_scapula', topic: "3. สะบัก", select: null },
                        { name: 'pain_back', topic: "4. หลังส่วนเอว", select: null },
                        { name: 'pain_hip', topic: "5. สะโพก/ต้นขา", select: null },
                        { name: 'pain_knee', topic: "6. ข้อเข่า/น่อง", select: null },
                    ];
                }

                state.entity.tablePain = newTablePain;
            },

        },
        actions: {
            handlerEntries({ commit }, payload) {
                return Promise.resolve(true)
            },
            handlerFieldsData({ commit }, payload) {
                commit('setBirthday', payload)
                commit('setSurveyDate', payload)
            },
            handlerRefuseFields({ commit }, payload) {
                const { new: newV, old: oldV, name } = payload
                //console.log('newV ::==', newV)
                //console.log('oldV ::==', oldV)
                if (newV.length > 1 && newV?.includes('c99')) {
                    //console.log('xxxxx')
                    let value_ = newV
                    if (oldV?.includes('c99')) {
                        const idxc99 = newV['c99']
                        value_.splice(idxc99, 1)
                    } else {
                        value_ = ['c99']
                    }
                    commit('setEntryFieldByKey', {
                        [name]: value_
                    })
                }
            },
            handlerRefuseFieldsCareerNeed({ commit }, payload) {
                const { new: newV, old: oldV, name } = payload
                //console.log('newV ::==', newV)
                //console.log('oldV ::==', oldV)
                if (newV.length > 1 && newV?.includes('c99')) {
                    //console.log('xxxxx')
                    let value_ = newV
                    if (oldV?.includes('c99')) {
                        const idxc99 = newV['c99']
                        value_.splice(idxc99, 1)
                    } else {
                        value_ = ['c99']
                    }
                    commit('setEntryFieldByKey', {
                        [name]: value_
                    })
                }
                if (newV.length > 1 && newV?.includes('c8')) {
                    //console.log('xxxxx')
                    let value_ = newV
                    if (oldV?.includes('c8')) {
                        const idxc8 = newV['c8']
                        value_.splice(idxc8, 1)
                    } else {
                        value_ = ['c8']
                    }
                    commit('setEntryFieldByKey', {
                        [name]: value_
                    })
                }
            }
        },
        namespaced: true,
    }
}

export default entryTrain