import * as _ from 'lodash'

const groupArea = () => {

}

const pivot = (axios) => {
    return {
        state: {
            unAreaZone: [
                { name: 'สำรวจแล้ว', servey: 0 },
                { name: 'ยังไม่สำรวจ', servey: 0 }
            ],
            areaZone: [
                { name: 'สำรวจแล้ว', servey: 0 },
            ]
        },
        mutations: {

        },
        actions: {
            async fetchElderliesGroupSurveyByAreaId({ commit }, areaCodeId) {
                try {
                    console.log('areaCodeId ::==', areaCodeId)
                    const response = await axios({
                        url: `/pivot/get/elderly/group/survey/areaId/${areaCodeId}`,
                        method: 'GET',
                    }).then(http => http.data)
                    return new Promise(resolve => {
                        const { data } = response
                        // console.log(`data ::== ${JSON.stringify(data)}`)
                        resolve(response)
                    })
                } catch (error) {
                    return Promise.reject(error);
                }
            },
            async fetchElderliesByAreaId({ commit }, areaCodeId) {
                try {
                    console.log('areaCodeId ::==', areaCodeId)
                    const response = await axios({
                        url: `/pivot/get/elderly/areaId/${areaCodeId}`,
                        method: 'GET',
                    }).then(http => http.data)
                    return new Promise(resolve => {
                        const { data } = response
                        // console.log(`data ::== ${JSON.stringify(data)}`)
                        resolve(response)
                    })
                } catch (error) {
                    return Promise.reject(error);
                }
            },
            async fetchElderlies({ commit }) {
                try {
                    // console.log('areaCodeId ::==', areaCodeId)
                    const response = await axios({
                        url: `/pivot/get/elderly`,
                        method: 'GET',
                    }).then(http => http.data)
                    return new Promise(resolve => {
                        const { data } = response
                        // console.log(`data ::== ${JSON.stringify(data)}`)
                        resolve(response)
                    })
                } catch (error) {
                    return Promise.reject(error);
                }
            },
            async fetchElderliesGroupAreaStatusSurvey({ commit }, payload) {
                try {
                    // console.log('areaCodeId ::==', areaCodeId)
                    const response = await axios({
                        url: `/pivot/get/elderly/group/area/status/surveyName`,
                        method: 'GET',
                        params: payload
                    }).then(http => http.data)
                    return new Promise(resolve => {
                        const { data } = response
                        resolve(response)
                    })
                } catch (error) {
                    return Promise.reject(error);
                }
            },
            async fetchElderliesStatusSurvey({ commit }, payload) {
                try {
                    // console.log('areaCodeId ::==', areaCodeId)
                    const response = await axios({
                        url: `/pivot/get/elderly/status/surveyName`,
                        method: 'GET',
                        params: payload
                    }).then(http => http.data)
                    return new Promise(resolve => {
                        const { data } = response
                        resolve(response)
                    })
                } catch (error) {
                    return Promise.reject(error);
                }
            },
            async fetchGraphSeriesElderly({ commit }, payload) {
                try {
                    // console.log('areaCodeId ::==', areaCodeId)
                    const response = await axios({
                        url: `/pivot/get/graph/series/elderly`,
                        method: 'GET',
                        params: payload
                    }).then(http => http.data)
                    return new Promise(resolve => {
                        const { data } = response
                        resolve(data)
                    })
                } catch (error) {
                    return Promise.reject(error);
                }
            },
            async fetchGraphSeriesAnalytise({ commit }, payload) {
                try {
                    // console.log('areaCodeId ::==', areaCodeId)
                    const { url } = payload
                    const URLS = {
                        'INDEX': '/pivot/get/graph/series/analytics/index',
                        'BASICELDER': '/pivot/get/graph/series/analytics/basicElder',
                        'BODYPAIN': '/pivot/get/graph/series/analytics/bodyPainCondition',
                        'ECON': '/pivot/get/graph/series/analytics/econ',
                        'HEALTH': '/pivot/get/graph/series/analytics/healthCondition',
                        'OCCUPATION': '/pivot/get/graph/series/analytics/occupation',
                        'TMSE': '/pivot/get/graph/series/analytics/tmse'
                    }
                    const response = await axios({
                        url: `${URLS[url]}`, ///pivot/get/graph/series/elderly
                        method: 'GET',
                        params: payload
                    }).then(http => http.data)
                    return new Promise(resolve => {
                        const { data } = response
                        resolve(data)
                    })
                } catch (error) {
                    return Promise.reject(error);
                }
            },
        },
        namespaced: true,
    }
}
export default pivot;