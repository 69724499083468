const address = (axios) => {
    return {
        state: {
            provinces: [],
            districts: [],
            amphures: [],
            geographies: []
        },
        mutations: {
            setProvinces(state, payload) {
                state.provinces = payload;
            },
            setAmphures(state, payload) {
                state.amphures = payload;
            },
            setDistricts(state, payload) {
                state.districts = payload;
            },
            setGeographies(state, payload) {
                state.geographies = payload;
            }
        },
        actions: {
            async fetchProvincesOrderNameTh({ commit }) {
                try {
                    const response = await axios.get(`/province/all/order/nameTh`).then(http => http.data)
                    return new Promise(resolve => {
                        const { data } = response
                        // console.log(`data ::== ${JSON.stringify(data)}`)
                        commit('setProvinces', data);
                        resolve(response)
                    })
                } catch (error) {
                    return Promise.reject(error);
                }
            },
            async fetchAmphuresOrderNameTh({ commit }) {
                try {
                    const response = await axios.get(`/amphure/all/order/nameTh`).then(http => http.data)
                    return new Promise(resolve => {
                        const { data } = response
                        // console.log(`data ::== ${JSON.stringify(data)}`)
                        commit('setAmphures', data);
                        resolve(response)
                    })
                } catch (error) {
                    return Promise.reject(error);
                }
            },
            async fetchDistrictsOrderNameTh({ commit }) {
                try {
                    const response = await axios.get(`/district/all/order/nameTh`).then(http => http.data)
                    return new Promise(resolve => {
                        const { data } = response
                        // console.log(`data ::== ${JSON.stringify(data)}`)
                        commit('setDistricts', data);
                        resolve(response)
                    })
                } catch (error) {
                    return Promise.reject(error);
                }
            },
            async fetchAmphuresByProvinceIdOrderNameTh({ commit }, payload) {
                try {
                    const { provinceId } = payload
                    const response = await axios.get(`/amphure/get/provinceId/${provinceId}/order/nameTh`).then(http => http.data)
                    return new Promise(resolve => {
                        const { data } = response
                        // console.log(`data ::== ${JSON.stringify(data)}`)
                        commit('setAmphures', data);
                        resolve(response)
                    })
                } catch (error) {
                    return Promise.reject(error);
                }
            },
            async fetchDistrictsByAmphureIdOrderNameTh({ commit }, payload) {
                try {
                    const { amphureId } = payload
                    console.log('amphureId ::==', amphureId)
                    const response = await axios.get(`/district/get/amphureId/${amphureId}/order/nameTh`).then(http => http.data)
                    return new Promise(resolve => {
                        const { data } = response
                        // console.log(`data ::== ${JSON.stringify(data)}`)
                        commit('setDistricts', data);
                        resolve(response)
                    })
                } catch (error) {
                    return Promise.reject(error);
                }
            },
        },
        namespaced: true,
    }
}

export default address;