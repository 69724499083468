import { createStore } from 'vuex'

import elderly from './elderly'
import oauth from './oauth'
import entry from './entry'
import entryTrain from './entryTrain'
import dialog from './dialog'
import user from './user';
import area from './area'
import menu from './menu'
import address from './address'
import constance from './constance'
import statistics from './statistics'
import pdpa from './pdpa'
import pivot from './pivot'
import activity from './activity'
import suggest from './suggest'
import recaptcha from './recaptcha'
import prediction from './prediction'
import word from './word'

const instance = (axios) => {
  return createStore({
    state: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
      constance,
      dialog,
      pivot: pivot(axios),
      pdpa: pdpa(axios),
      statistics: statistics(axios),
      elderly: elderly(axios),
      oauth: oauth(axios),
      entry: entry(axios),
      entryTrain: entryTrain(axios),
      user: user(axios),
      area: area(axios),
      address: address(axios),
      menu: menu(),
      activity: activity(axios),
      suggest: suggest(axios),
      recaptcha: recaptcha(axios),
      prediction,
      word: word(axios)
    }
  })

}


export default instance