import { createRouter, createWebHashHistory } from 'vue-router'  //createWebHashHistory
import { createRouterLayout } from 'vue-router-layout'

// Create <RouterLayout> component.
const RouterLayout = createRouterLayout(layout => {
  // Resolves a layout component with layout type string.
  console.log('layout ::==', layout)
  return import(`@/layouts/${layout}.vue`)
})


const routes = [
  {
    path: '/public',
    component: RouterLayout,
    children: [
      {
        path: '/',
        component: () => import('../views/Welcome.vue'),
        meta: {
          title: 'ยินดีต้อนรับสู่ ระบบวิเคราะห์ภาวะสุขภาพ'
        }
      },
    ],
  },
  {
    path: '/private',
    component: RouterLayout,
    children: [
      {
        path: '/analytic/index',
        name: 'AnalyticIndex',
        component: () => import('../views/Dashboard.vue'),
        meta: {
          //requiresAuth: true,
          //is_admin: true
          title: 'สถานะการสำรวจข้อมูลผู้สูงอายุ'
        }
      },
      {
        path: '/login',
        name: 'Login',
        component: () => import('../views/Login.vue'),
        meta: {
          //is_admin: true
          title: 'ลงชื่อเข้าใช้งานระบบ'
        }
      },
      {
        path: '/logout',
        name: 'Logout',
        component: () => import('../views/Logout.vue'),
        meta: {
          //is_admin: true
          title: 'ลงชื่อออกจากระบบ'
        }
      },

      // ---------------------- elder ----------------------------
      {
        path: '/elder/form/:id',
        name: 'ElderFormId',
        component: () => import('../views/elder/ElderForm.vue'),
        meta: {
          requiresAuth: true,
          is_admin: true,
          title: 'จัดการข้อมูลผู้สูงอายุ'
        }
      },
      {
        path: '/elder/form',
        name: 'ElderForm',
        component: () => import('../views/elder/ElderForm.vue'),
        meta: {
          requiresAuth: true,
          is_admin: true,
          title: 'จัดการข้อมูลผู้สูงอายุ'
        }
      },
      {
        path: '/elder/list',
        name: 'ElderList',
        component: () => import('../views/elder/ElderList.vue'),
        meta: {
          requiresAuth: true,
          is_admin: true,
          title: 'จัดการข้อมูลผู้สูงอายุ'
        }
      },
      {
        path: '/elder/import/health',
        name: 'ElderImportHealth',
        component: () => import('../views/elder/ElderImportHealth.vue'),
        meta: {
          requiresAuth: true,
          is_admin: true,
          title: 'นำเข้าข้อมูลในระบบแบบ Health'
        }
      },
      {
        path: '/elder/import/nonhealth',
        name: 'ElderImportNonHealth',
        component: () => import('../views/elder/ElderImportNonHealth.vue'),
        meta: {
          requiresAuth: true,
          is_admin: true,
          title: 'นำเข้าข้อมูลในระบบแบบ Non Health'
        }
      },
      // ---------------------- area ----------------------------
      {
        path: '/area/list',
        name: 'AreaList',
        component: () => import('../views/area/AreaList.vue'),
        meta: {
          requiresAuth: true,
          is_admin: true,
          title: 'จัดการข้อมูลพื้นที่'
        }
      },
      {
        path: '/area/form',
        name: 'AreaForm',
        component: () => import('../views/area/AreaForm.vue'),
        meta: {
          requiresAuth: true,
          is_admin: true,
          title: 'แบบฟอร์มบันทึกข้อมูลพื้นที่'
        }
      },
      {
        path: '/area/form/:id',
        name: 'AreaFormId',
        component: () => import('../views/area/AreaForm.vue'),
        meta: {
          requiresAuth: true,
          is_admin: true,
          title: 'แบบฟอร์มบันทึกข้อมูลพื้นที่'
        }
      },
      // ---------------------- user ----------------------------
      {
        path: '/user/list',
        name: 'UserList',
        component: () => import('../views/user/UserList.vue'),
        meta: {
          requiresAuth: true,
          is_admin: true,
          title: 'จัดการข้อมูลผู้ใช้งาน'
        }
      },
      {
        path: '/user/form',
        name: 'UserForm',
        component: () => import('../views/user/UserForm.vue'),
        meta: {
          requiresAuth: true,
          is_admin: true,
          title: 'แบบฟอร์มบันทึกข้อมูลผู้ใช้งาน'
        }
      },
      {
        path: '/user/form/:id',
        name: 'UserFormId',
        component: () => import('../views/user/UserForm.vue'),
        meta: {
          requiresAuth: true,
          is_admin: true,
          title: 'แบบฟอร์มบันทึกข้อมูลผู้ใช้งาน'
        }
      },
      // ---------------------- profile ----------------------------
      {
        path: '/profile/password',
        name: 'ProfilePassword',
        component: () => import('../views/profile/ChangePassword.vue'),
        meta: {
          requiresAuth: true,
          is_admin: true,
          title: 'เปลี่ยนรหัสผ่าน'
        }
      },
      {
        path: '/profile/info',
        name: 'ProfileInfo',
        component: () => import('../views/profile/ChangeProfile.vue'),
        meta: {
          requiresAuth: true,
          is_admin: true,
          title: 'แบบฟอร์มแก้ไขข้อมูลส่วนตัว'
        }
      },
      {
        path: '/profile/activity/history',
        name: 'ProfileActivityHistory',
        component: () => import('../views/profile/ActivityHistory.vue'),
        meta: {
          requiresAuth: true,
          is_admin: true,
          title: 'ประวัติการเข้าใช้งานระบบ'
        }
      },
      {
        path: '/profile/revoke',
        name: 'ProfileRevoke',
        component: () => import('../views/pdpa/Revoke.vue'),
        meta: {
          //is_admin: true
          title: 'ลงชื่อออกจากระบบ'
        }
      },
      {
        path: '/forgot/password',
        name: 'ForgotPassword',
        component: () => import('../views/ForgotPassword.vue'),
        meta: {
          //requiresAuth: true,
          //is_admin: true
          title: 'แบบฟอร์มเปลี่ยนข้อมูลรหัสผ่าน'
        }
      },
      {
        path: '/reset/password',
        name: 'ResetPassword',
        component: () => import('../views/ResetPassword.vue'),
        meta: {
          //requiresAuth: true,
          //is_admin: true
          title: 'ตั้งค่ารหัสผ่านใหม่'
        }
      },

      // ----------------------------- suggest --------------------------
      {
        path: '/suggest',
        name: 'SuggestForm',
        component: () => import('../views/suggest/SuggestForm.vue'),
        meta: {
          title: 'ข้อเสนอแนะ/ข้อมูลที่อยากได้จากระบบ'
        }
      },
      {
        path: '/suggests',
        name: 'SuggestList',
        component: () => import('../views/suggest/SuggestList.vue'),
        meta: {
          title: 'ข้อเสนอแนะ/ข้อมูลที่อยากได้จากระบบ'
        }
      },
      // ----------------------------- virtualization --------------------------
      {
        path: '/plot/boxplot',
        name: 'plotBoxplot',
        component: () => import('../views/plot/Boxplot.vue'),
        meta: {
          requiresAuth: true,
          title: 'กราฟวิเคราะห์ข้อมูล'
        }
      },
      {
        path: '/plot/barplot',
        name: 'plotBarplot',
        component: () => import('../views/plot/Barplot.vue'),
        meta: {
          requiresAuth: true,
          title: 'กราฟวิเคราะห์ข้อมูลแบบแท่ง'
        }
      },
      {
        path: '/prediction/mltrain',
        name: 'mlTraining',
        component: () => import('../views/prediction/MLtraining.vue'),
        meta: {
          requiresAuth: true,
          title: 'เฟส 1 - แบบจำลองการประเมินความสามารถในการทำงาน'
        }
      },
      {
        path: '/prediction/mltrainHealth',
        name: 'mlTrainingHealth',
        component: () => import('../views/prediction/MLtrainingHealth.vue'),
        meta: {
          requiresAuth: true,
          title: 'เฟส 2 - แบบจำลองการประเมินความสามารถในการทำงาน (ตัวแปรสุขภาพ)'
        }
      },
      {
        path: '/prediction/mltrainTotal',
        name: 'mlTrainingTotal',
        component: () => import('../views/prediction/MLtrainingTotal.vue'),
        meta: {
          requiresAuth: true,
          title: 'เฟส 2 - แบบจำลองการประเมินความสามารถในการทำงาน (ตัวแปรรวม)'
        }
      },
      // ----------------------------- virtualization --------------------------
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import('../views/analytic/Index.vue'),
        meta: {
          //requiresAuth: true,
          title: 'Dashboard'
        },
      },
      {
        path: '/analytic/info/elderly',
        name: 'InfoBasicElderly',
        component: () => import('../views/analytic/InfoBasicElderly.vue'),
        meta: {
          //requiresAuth: true,
          title: 'วิเคราะห์ข้อมูลพื้นฐานของผู้สูงอายุ'
        }
      },
      {
        path: '/analytic/info/healthcondition',
        name: 'InfoHealthCondition',
        component: () => import('../views/analytic/InfoHealthCondition.vue'),
        meta: {
          //requiresAuth: true,
          title: 'วิเคราะห์ภาวะสุขภาพ และโรคเรื้อรัง'
        }
      },
      {
        path: '/analytic/info/bodypaincondition',
        name: 'InfoBodyPainCondition',
        component: () => import('../views/analytic/InfoBodyPainCondition.vue'),
        meta: {
          //requiresAuth: true,
          title: 'ภาวะความเจ็บปวดของร่างกาย'
        }
      },
      {
        path: '/analytic/info/tmse',
        name: 'InfoTMSE',
        component: () => import('../views/analytic/InfoTMSE.vue'),
        meta: {
          //requiresAuth: true,
          title: 'สมรรถภาพสมอง (TMSE) และภาวะเครียด (ST5)'
        }
      },
      {
        path: '/analytic/info/econ',
        name: 'InfoEcon',
        component: () => import('../views/analytic/InfoEcon.vue'),
        meta: {
          //requiresAuth: true,
          title: 'เศรษฐานะ (ECON)'
        }
      },
      {
        path: '/analytic/info/occupation',
        name: 'InfoOccupation',
        component: () => import('../views/analytic/InfoOccupation.vue'),
        meta: {
          //requiresAuth: true,
          title: 'อาชีพ'
        }
      },
      {
        path: '/analytic/info/tenvaliablework',
        name: 'InfoTenValiableWork',
        component: () => import('../views/analytic/InfoTenValiableWork.vue'),
        meta: {
          //requiresAuth: true,
          title: '10 ตัวแปรที่ส่งผลกับความสามารถในการทำงาน'
        }
      },
      {
        path: '/analytic/info/careervaliablework',
        name: 'InfoCareerValiableWork',
        component: () => import('../views/analytic/InfoCareerValiableWork.vue'),
        meta: {
          //requiresAuth: true,
          title: 'ตัวแปรที่ส่งผลต่อความสามารถในการทำงานของแต่ละอาชีพ'
        }
      },
      
      // ----------------------------- prediction_career --------------------------
      {
        path: '/prediction/career',
        name: 'PredictionCareer',
        component: () => import('../views/prediction_career/CareerTrain.vue'),
        meta: {
          //requiresAuth: true,
          title: 'เฟส 1 - แบบจำลองการประเมินความสามารถในการประกอบอาชีพ'
        }
      },
      {
        path: '/prediction/careerByCareerTrain',
        name: 'PredictionCareerByCareerTrain',
        component: () => import('../views/prediction_career/CareerByCareerTrain.vue'),
        meta: {
          //requiresAuth: true,
          title: 'เฟส 2 – แบบจำลองประเมินความสามารถในการประกอบอาชีพของแต่ละอาชีพ'
        }
      },

      // ------------------------------ video --------------------------------------------
      {
        path: '/videos/tutorial',
        name: 'VideosTutorial',
        component: () => import('../views/video/Tutorial.vue'),
        meta: {
          //requiresAuth: true,
          title: 'แบบจำลองการประเมินความสามารถในการประกอบอาชีพ'
        },
      },
    ],
  },
]

const router = createRouter({
  //history: createWebHistory(), // , createWebHashHistory
  history: createWebHashHistory(), // createWebHistory , createWebHashHistory
  routes
})

const { VUE_APP_AUTH_MODE } = process.env
console.log('VUE_APP_AUTH_MODE ::==' + typeof VUE_APP_AUTH_MODE)

router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  if (VUE_APP_AUTH_MODE === 'false') {
    next()
  } else {
    if (to.matched.some(record => record.meta.requiresAuth)) {
      if (localStorage.getItem('jwt') == null) {
        next({
          path: '/login',
          params: { nextUrl: to.fullPath }
        })
      } else {
        //let user = JSON.parse(localStorage.getItem('user'))
        if (to.matched.some(record => record.meta.is_admin)) {
          /*if (user.is_admin == 1) {
            next()
          }
          else {
            next({ name: 'userboard' })
          }*/
          next()
        } else {
          next()
        }
      }
    } else if (to.matched.some(record => record.meta.guest)) {
      if (localStorage.getItem('jwt') == null) {
        next()
      }
      else {
        next({ name: 'userboard' })
      }
    } else {
      next()
    }
  }
})


export default router
