const suggest = (axios) => {
    return {
        state: {
            suggests: []
        },
        mutations: {
            setSuggests(state, payload) {
                state.suggests = payload
            }
        },
        actions: {
            async fetchSuggests({ commit }, payload) {
                try {
                    const response = await axios({
                        url: `/suggest/all/order/createDtm`,
                        method: 'GET',
                        transformRequest: (data, headers) => {
                            delete headers.common['Authorization'];
                            return JSON.stringify(data);
                        },
                        params: { sort: 'ASC' }
                    }).then(http => http.data)
                    return new Promise(resolve => {
                        const { data } = response
                        commit('setSuggests', data)
                        resolve(response)
                    })
                } catch (error) {
                    return Promise.reject(error);
                }
            },
            async saveSuggests({ commit }, payload) {
                try {
                    const response = await axios({
                        url: `/suggest/save`,
                        method: 'post',
                        transformRequest: (data, headers) => {
                            delete headers.common['Authorization'];
                            return JSON.stringify(data);
                        },
                        data: payload
                    }).then(http => http.data)
                    return new Promise(resolve => {
                        const { data } = response
                        resolve(response)
                    })
                } catch (error) {
                    return Promise.reject(error);
                }
            },
        },
        getters: {

        },
        namespaced: true,
    }
}
export default suggest;