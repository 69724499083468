const pdpa = (axios) => {
    return {
        state: {
            consent: null,
            isOpen: false,
            isAccepted: true,
        },
        mutations: {
            setConsent(state, payload) {
                state.consent = payload
            },
            setOpen(state, payload) {
                state.isOpen = payload
            },
            setAccepted(state, payload) {
                state.isAccepted = payload
            },
            setPdpa(state, payload) {
                const { isOpen, isAccepted } = payload
                state.isOpen = isOpen
                state.isAccepted = isAccepted
            }
        },
        actions: {
            async fetchUserConsent({ commit }, payload) {
                try {
                    const { userId, consentStatus } = payload
                    const response = await axios({
                        url: `/pdpa/consent/get/userId/${userId}/status/${consentStatus}`,
                        method: 'GET',
                    }).then(http => http.data)
                    return new Promise(resolve => {
                        const { data } = response
                        //console.log(`data ::== ${JSON.stringify(data)}`)
                        commit('setConsent', data)
                        resolve(response)
                    })
                } catch (error) {
                    return Promise.reject(error);
                }
            },
            async saveUserConsent({ commit }, payload) {
                try {
                    const response = await axios({
                        url: `/pdpa/consent/save`,
                        method: 'POST',
                        data: payload
                    }).then(http => http.data)
                    return new Promise(resolve => {
                        const { data } = response
                        console.log(`data ::== ${JSON.stringify(data)}`)
                        resolve(response)
                    })
                } catch (error) {
                    return Promise.reject(error);
                }
            },
            async revokeUserConsent({ commit }, payload) {
                try {
                    const response = await axios({
                        url: `/pdpa/consent/revoke`,
                        method: 'POST',
                        data: payload
                    }).then(http => http.data)
                    return new Promise(resolve => {
                        const { data } = response
                        console.log(`data ::== ${JSON.stringify(data)}`)
                        resolve(response)
                    })
                } catch (error) {
                    return Promise.reject(error);
                }
            }
        },
        namespaced: true,
    }
}

export default pdpa;