const oauth = (axios) => {
    return {
        state: {
            auth: {
                username: null,
                password: null
            },
            user: null,
            consent: null,
        },
        mutations: {
            setUser(state, payload) {
                state.user = payload
            },
            setConsent(state, payload) {
                console.log('setConsent ::==', payload)
                state.consent = payload
            },
            clearSession() {
                localStorage.removeItem("user");
                localStorage.removeItem("jwt");
                localStorage.removeItem("consent");
            }
        },
        actions: {
            async getUserInfo({ commit }) {
                const { status, code, data } = await axios({
                    url: '/user/userinfo',
                    method: 'get',
                }).then(http => http.data);

                //console.log('data ::==', data)
                if (code == 200) {
                    const { user, consent } = data;
                    commit('setUser', user)
                    commit('setConsent', consent)
                    return new Promise(resolve => {
                        resolve(data)
                    })
                } else {
                    throw Error('User Not Found!')
                }
            },
            async handleAuthentication({ commit }, payload) {

                //console.log('process.env.VUE_APP_API_ENDPOINT ::==', process.env.VUE_APP_API_ENDPOINT)
                const { VUE_APP_API_CLIENT_ID, VUE_APP_API_CLIENT_PASS } = process.env

                console.log('payload ::==', payload)
                try {
                    const { status, data } = await axios({
                        url: '/oauth/token',
                        method: 'post',
                        params: {
                            ...payload,
                            grant_type: 'password'
                        },
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                        auth: {
                            username: VUE_APP_API_CLIENT_ID,
                            password: VUE_APP_API_CLIENT_PASS
                        }
                    })
                    if (status == 200) {
                        return new Promise(resolve => {
                            resolve(data)
                        })
                    }
                } catch (error) {
                    console.log('err ::==', error)
                    const { status } = error;
                    if (401 == status) {
                        return new Promise(resolve => {
                            resolve(error)
                        })
                    } else {
                        throw error
                    }
                }
            }
        },
        getters: {
            consent: state => {
                const consent = localStorage.getItem('consent')
                if (consent) {
                    return JSON.parse(consent)
                } else {
                    return {

                    }
                }
            },
            session: state => {
                const user = localStorage.getItem('user')
                if (user) {
                    return JSON.parse(user)
                } else {
                    return {
                        area: { codeId: null, areaCode: null },
                        codeId: null,
                        createBy: null,
                        createDtm: null,
                        email: null,
                        failAttempt: null,
                        fname: null,
                        lastLoginDtm: null,
                        lname: null,
                        mobile: null,
                        securePassword: null,
                        tel: null,
                        userLevel: null,
                        userName: null,
                        userPassword: null,
                        userStatus: null,
                    }
                }
            }
        },
        namespaced: true,

    }
}

export default oauth;