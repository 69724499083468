const recaptcha = (axios) => {
    return {
        state: {

        },
        mutations: {

        },
        actions: {
            async verifyTokenClient({ commit }, payload) {
                const { VUE_APP_RECAPTCHA_SECRET_KEY } = process.env
                try {

                    const response = await axios({
                        url: `/validation/recaptcha`,
                        method: 'POST',
                        transformRequest: (data, headers) => {
                            delete headers.common['Authorization'];
                            return JSON.stringify(data);
                        },
                        params: {
                            secret: VUE_APP_RECAPTCHA_SECRET_KEY,
                            'g-recaptcha-response': payload
                        }
                    }).then(http => http.data)
                    return new Promise(resolve => {
                        const { data } = response
                        console.log(`data ::== ${JSON.stringify(data)}`)
                        resolve(data)
                    })

                    /*const response = await axios({
                        url: `https://www.google.com/recaptcha/api/siteverify`,
                        transformRequest: (data, headers) => {
                            delete headers.common['Authorization'];
                            return JSON.stringify(data);
                        },
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded; charset=utf-8"
                        },
                        params: {
                            secret: VUE_APP_RECAPTCHA_SECRET_KEY,
                            response: payload
                        },
                    }).then(http => http.data)
                    return new Promise(resolve => {
                        const { data } = response
                        // console.log(`data ::== ${JSON.stringify(data)}`)
                        resolve(response)
                    })*/
                } catch (error) {
                    return Promise.reject(error);
                }
            }
        },
        getters: {

        },
        namespaced: true,
    }
}

export default recaptcha