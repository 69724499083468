const word = (axios) => {
    return {
        state: {
            words: {}
        },
        mutations: {
            setWords(state, payload) {
                state.words = payload
            }
        },
        actions: {
            async fetchGraphWords({ commit }, payload) {
                try {
                    const response = await axios.get(`/graph/wordings/checked/exsiting`, {
                        params: payload
                    })
                        .then(http => http.data)
                        .catch(error => {
                            console.log('error ::==', error)
                            commit('setWords', {})
                        })
                    return new Promise(resolve => {
                        const { code, data } = response
                        commit('setWords', data)
                        resolve(response)
                    })
                } catch (error) {
                    return Promise.reject(error);
                }
            },
        },
        getters: {
            getWords(state) {
                return state.words == null ? {} : state.words;
            }
        },
        namespaced: true,
    }
}

export default word;