import { isNull, isEmpty } from 'lodash'
import * as moment from 'moment'

import { Trades, Orcards, Rubbers, Farms, Rices, Nannys, Salepromotions } from './decision'


const isNullThen = (value) => {
    //console.log('value ::==', value)
    const result = isNull(value) ? "[]" : isEmpty(value) ? "[]" : value
    // console.log('result ::==', result)
    return result
}
const setRadioSelected = (valueFields, labelFields) => {
    // valueFields = {"painNeck" : "1","painElbow" : "1","painFoot" : "1","painHip" : "1",}
    // radioFields = ['painNeck','painNeck',....]
    let isSelected = false;
    const radios = labelFields.map(field => {
        const { name, topic, select } = field
        const value = valueFields[name]
        return { name, topic, select: value == null ? null : parseInt(value), };
    })
    //{ name: 'painNeck', topic: "1. คอ", select: null, },
    //console.log('radios ::==', radios)
    return radios;
}

const setCheckboxChecked = (valueFields) => {
    const {
        medical, restroom, useSmartphone, takecareType, helpOrg, incomeSource, careerCurrent,
        workTravelType, noNeedJobReason, workFor, expectCareerGroup, workLocation,
        skillSpecial, diseaseDetail, careerNeed
    } = valueFields
    return {
        medical_: setRefuse(medical),
        restroom_: setRefuse(restroom),
        useSmartphone_: setRefuse(useSmartphone),
        //takecareType_: setRefuse(takecareType),
        helpOrg_: setRefuse(helpOrg),
        incomeSource_: setRefuse(incomeSource),
        careerCurrent_: setRefuse(careerCurrent),
        workTravelType_: setRefuse(workTravelType),
        noNeedJobReason_: setRefuse(noNeedJobReason),
        workFor_: setRefuse(workFor),
        expectCareerGroup_: setRefuse(expectCareerGroup),
        workLocation_: setRefuse(workLocation),
        skillSpecial_: setRefuse(skillSpecial),
        diseaseDetail_: setRefuse(diseaseDetail),
        careerNeed_: setRefuse(careerNeed)
    }
}

/*const setRadioSelectedRefues = (valueFields, radioFields) => {
    // valueFields : { name: 'painNeck', topic: "1. คอ", select: null, },
    // radioFields : ['maritalStatus','xxx','yyy','zzz']
    let radios = {};
    (radioFields || []).forEach((name) => {
        const value = valueFields[name]
        radios[name] = value ? value : 'c99'
    })
    return radios
}*/

const setRefuse = (valueFields) => {
    const values = JSON.parse(isNullThen(valueFields))
    return values.length == 0 ? [] : values
}

const entry = (axios) => {
    //console.log('axios ::', axios)
    const sectionHead = {
        codeId: null,
        mappingCode: null,
        areaId: null,
        surveyName: null,
        surveyDate: null,
        surveyStartTime: null,
        surveyEndTime: null
    }

    const sectionProfile = {
        gender: null,
        birthday: null,
        weight: null,
        height: null,
        waistline: null,
        memberTotal: null,
        memberEarnMoney: null,
        memberTakecare: null
    }

    const section1 = {
        education: null,
        maritalStatus: null,
        maritalStatusOth: null,
        healthy: null,
        healthyOth: null,
        medical: [],
        medicalOth: null,
        residential: null,
        houseLive: null,
        houseBelong: null,
        houseBelongOth: null,
        bedroom: null,
        restroom: null,
        useSmartphone: null,
        elderlyCare: null,
        needElderlyCare: null,
        takecareType: null,
        takecareOth: null,
        helpOrg: null,
        helpSkill: null,
        helpMedical: null,
        helpOrgOth: null
    }
    const section2 = {
        incomeTotal: null,
        incomeSource: null,
        incomeOth: null,
        expendAvg: null,
        expendMedicine: null,
        expendOPD: null,
        expendIPD: null,
        incomeEnough: null,
        careerFlag: null,
        careerCurrent: null,
        careerCurrentOth: null,
        incomeMainTotal: null,
        incomeAmt: null,
        incomeDetail: null,
        incomeDetailOth: null,
        workWeekHr: null,
        distantKm: null,
        distantM: null,
        durationHr: null,
        durationMin: null,
        workPlace: null,
        workTravelType: null,
        workTravelOth: null,
        accident: null,
        accidentDetail: null,
        needJob: null,
        needJobHr: null,
        wagesFlag: null,
        wagesExpect: null,
        jobExpect: null,
        noNeedJobReason: null,
        noNeedJobOth: null,
        workFor: null,
        workForOth: null,
        expectCareerGroup: null,
        expectCareerOth: null,
        expectJobHr: null,
        expectWagesHrType: null,
        expectWagesHr: null,
        specWork: null,
        canWork: null,
        canWorkOth: null,
        workLocation: null,
        canTravelSelf: null,
        canTravelSelfOth: null,
        workDaytime: null,
        workNight: null,
        canRun: null,
        canArm: null,
        canShrink: null,
        canSee: null,
        skillSpecial: null,
        skillSpecialOth: null,
        skillSpecialDetail: null
    }
    const section3 = {
        stsSleep: null,
        stsConcentration: null,
        stsEDGY: null,
        stsBored: null,
        stsIntrovert: null,
        eyes: null,
        depression: null,
        fallTimes: null,
        machineWalk: null,
        osteoarthritis: null,
        tmse1: null,
        tmse2: null,
        tmse3: null,
        tmse4: null,
        tmse5: null,
        tmse6: null,
        tmse7: null,
        tmse8: null,
        tmse9: null,
        tmse10: null,
        tmse11: null,
        tmse12: null,
        tmse13: null,
        tmse14: null,
        tmse15: null,
        tmse16: null,
        tmse17: null,
        adl1: null,
        adl2: null,
        adl3: null,
        adl4: null,
        adl5: null,
        adl6: null,
        adl7: null,
        adl8: null,
        adl9: null,
        adl10: null,
        adlScore: null,
        painNeck: null,
        painShoulder: null,
        painScapula: null,
        painElbow: null,
        painHand: null,
        painBack: null,
        painHip: null,
        painKnee: null,
        painFoot: null,
        healthNeck: null,
        healthBack: null,
        healthMuscle: null,
        healthMove: null,
        healthSit: null,
        healthStand: null,
        healthBow: null,
        healthSquatting: null,
        healthKneel: null,
        healthHeavy: null,
        healthLifting: null,
        healthCatch: null,
        healthThink: null,
        healthDoing: null,
        healthHearing: null,
        healthSee: null,
        healthEye: null,
        healthHand: null,
        healthSleep: null,
        healthSkin: null,
        healthFall: null,
        decisionTablePain: [
            { name: 'painNeck', topic: "1. คอ", select: null, },
            { name: 'painShoulder', topic: "2. ไหล่", select: null, },
            { name: 'painScapula', topic: "3. สะบัก", select: null, },
            { name: 'painElbow', topic: "4. ข้อศอก/แขน", select: null, },
            { name: 'painHand', topic: "5. ข้อมือ/มือ/นิ้ว", select: null, },
            { name: 'painBack', topic: "6. หลังส่วนเอว", select: null, },
            { name: 'painHip', topic: "7. สะโพก/ต้นขา", select: null, },
            { name: 'painKnee', topic: "8. ข้อเข่า/น่อง", select: null, },
            { name: 'painFoot', topic: "9. ข้อเท้า/เท้า/นิ้ว", select: null, },
        ],
    }
    const section4 = {
        workBody: null,
        workThink: null,
        workEffect: null,
        workLeave: null,
        work2yrs: null,
        feelFun: null,
        feelActive: null,
        feelHope: null,
        disease: null,
        diseaseDetail: null,
        diseaseOth: null,
        decisionTableAbility: [
            { name: 'workSkill', topic: '', select: null },
        ]
    }

    const section5 = {
        careerNeed: null,
        careerNeedOth: null,
        careerNeedReason: null,
        decisionTableTrade: Trades,
        decisionTableFarm: Farms,
        decisionTableGardener: Rubbers,
        decisionTableFruit: Orcards,
        decisionTableRice: Rices,
        decisionTableNanny: Nannys,
        decisionTableSalepromotion :Salepromotions
    }

    return {
        state: {
            entity: {
                ...sectionHead,
                ...sectionProfile,
                ...section1,
                ...section2,
                ...section3,
                ...section4,
                ...section5
            },
            birthday: {
                bdDay: null,
                bdMonth: null,
                bdYear: null,
            },
            surveyDate: {
                svDay: null,
                svMonth: null,
                svYear: null,
            }
        },
        mutations: {
            setBirthday(state, payload) {
                const { birthday } = payload;
                if (birthday) {
                    console.log("birthdate ::==" + birthday);
                    const [day, month, year] = (birthday || "").split("/");
                    console.log(
                        "day ::==" + day + "  month ::==" + month + "  year ::==" + year
                    );
                    state.birthday = {
                        bdDay: day,
                        bdMonth: month,
                        bdYear: moment(year, "YYYY").add(543, "years").format("YYYY"),
                    };
                } else {
                    state.birthday = {
                        bdDay: null,
                        bdMonth: null,
                        bdYear: null
                    };
                }

            },
            setSurveyDate(state, payload) {
                const { surveyDate } = payload;
                if (surveyDate) {
                    console.log("surveyDate ::==" + surveyDate);
                    const [day, month, year] = (surveyDate || "").split("/");
                    console.log(
                        "day ::==" + day + "  month ::==" + month + "  year ::==" + year
                    );
                    state.surveyDate = {
                        svDay: day,
                        svMonth: month,
                        svYear: moment(year, "YYYY").add(543, "years").format("YYYY"),
                    };
                } else {
                    const [day, month, year] = moment().add(543, "years").format('DD/MM/YYYY').split('/')
                    state.surveyDate = {
                        svDay: day,
                        svMonth: month,
                        svYear: year
                    };
                }

            },
            setEntity(state, payload) {

                state.entity = {
                    decisionTablePain: setRadioSelected(payload, section3.decisionTablePain),
                    decisionTableAbility: setRadioSelected(payload, section4.decisionTableAbility),
                    decisionTableFarm: setRadioSelected(payload, section5.decisionTableFarm),
                    decisionTableFruit: setRadioSelected(payload, section5.decisionTableFruit),
                    decisionTableGardener: setRadioSelected(payload, section5.decisionTableGardener),
                    decisionTableRice: setRadioSelected(payload, section5.decisionTableRice),
                    decisionTableNanny: setRadioSelected(payload, section5.decisionTableNanny),
                    decisionTableSalepromotion: setRadioSelected(payload, section5.decisionTableSalepromotion),
                    decisionTableTrade: setRadioSelected(payload, section5.decisionTableTrade),
                    ...payload,
                    // medical_ : "[\"c1\",\"c2\",\"c3\",\"c5\",\"c6\"]"  => ["c1","c2",]
                    ...setCheckboxChecked(payload)/*,
                    ...setRadioSelectedRefues(payload, [
                        'education', 'maritalStatus', 'residential', 'houseLive', 'houseBelong', 'bedroom', 'incomeEnough', 'careerFlag',
                        'incomeDetail', 'workPlace', 'accident', 'needJob', 'wagesFlag', 'expectWagesHrType', 'canWork', 'canTravelSelf',
                        'workDaytime', 'workNight', 'canRun', 'canArm', 'canShrink', 'canSee', 'stsSleep', 'stsConcentration',
                        'stsEDGY', 'stsBored', 'stsIntrovert', 'machineWalk', 'tmse1', 'tmse2', 'tmse3', 'tmse4',
                        'tmse5', 'tmse6', 'tmse7', 'tmse8', 'tmse9', 'tmse10', 'tmse11', 'tmse12',
                        'tmse13', 'tmse14', 'tmse15', 'tmse16', 'tmse17', 'healthNeck', 'healthBack', 'healthMuscle',
                        'healthMove', 'healthSit', 'healthStand', 'healthBow', 'healthSquatting', 'healthKneel', 'healthHeavy', 'healthLifting',
                        'healthCatch', 'healthThink', 'healthHearing', 'healthHand', 'healthSkin', 'workBody', 'workThink', 'workEffect',
                        'workLeave', 'work2yrs', 'feelFun', 'feelActive', 'feelHope', 'disease'])*/
                };
            },
            setEntryFieldByKey(state, payload) {
                Object.keys(payload).forEach(key => {
                    //console.log('key ::==', key)
                    state.entity[key] = payload[key]
                })
            }
        },
        actions: {
            handlerEntries({ commit }, payload) {
                return Promise.resolve(true)
            },
            handlerFieldsData({ commit }, payload) {
                commit('setBirthday', payload)
                commit('setSurveyDate', payload)
            },
            handlerRefuseFields({ commit }, payload) {
                const { new: newV, old: oldV, name } = payload
                //console.log('newV ::==', newV)
                //console.log('oldV ::==', oldV)
                if (newV.length > 1 && newV?.includes('c99')) {
                    //console.log('xxxxx')
                    let value_ = newV
                    if (oldV?.includes('c99')) {
                        const idxc99 = newV['c99']
                        value_.splice(idxc99, 1)
                    } else {
                        value_ = ['c99']
                    }
                    commit('setEntryFieldByKey', {
                        [name]: value_
                    })
                }
            },
            handlerRefuseFieldsCareerNeed({ commit }, payload) {
                const { new: newV, old: oldV, name } = payload
                //console.log('newV ::==', newV)
                //console.log('oldV ::==', oldV)
                if (newV.length > 1 && newV?.includes('c99')) {
                    //console.log('xxxxx')
                    let value_ = newV
                    if (oldV?.includes('c99')) {
                        const idxc99 = newV['c99']
                        value_.splice(idxc99, 1)
                    } else {
                        value_ = ['c99']
                    }
                    commit('setEntryFieldByKey', {
                        [name]: value_
                    })
                }
                if (newV.length > 1 && newV?.includes('c8')) {
                    //console.log('xxxxx')
                    let value_ = newV
                    if (oldV?.includes('c8')) {
                        const idxc8 = newV['c8']
                        value_.splice(idxc8, 1)
                    } else {
                        value_ = ['c8']
                    }
                    commit('setEntryFieldByKey', {
                        [name]: value_
                    })
                }
            }
        },
        namespaced: true,
    }
}

export default entry