const camelToSneckCase = str => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

const mapCamelKeyToSneckKey = (dicisions) => {
    return dicisions?.map((obj) => {
        const { name } = obj
        return { ...obj, name: camelToSneckCase(name) }
    })
}

export {
    camelToSneckCase,
    mapCamelKeyToSneckKey
}

export const Trades = [
    { name: 'tradeWash', topic: "1.สามารถล้างวัตถุดิบได้", select: null, },
    { name: 'tradeSlices', topic: "2.สามารถใช้มีดหั่นอาหารได้ เช่น ผักผลไม้ เนื้อ", select: null, },
    { name: 'tradeFried', topic: "3.สามารถประกอบอาหารได้ เช่น ผัด ทอด หุง ต้ม", select: null, },
    { name: 'tradeOpen', topic: "4.สามารถเปิดฝาขวดที่ปิดแน่น หรือฝาขวดที่ยังไม่ถูกเปิดมาก่อนได้", select: null, },
    { name: 'tradeReach', topic: "5.สามารถเอื้อมมือหยิบของบนชั้นวางของสูงเกินระดับหัวไหล่ได้", select: null, },
    { name: 'tradeRaise', topic: "6.สามารถยกของเพื่อจัดร้าน/เก็บร้านได้", select: null, },
    { name: 'tradeCarry', topic: "7.สามารถหิ้วถุงใส่ของหรือหิ้วกระเป๋าได้", select: null, },
    { name: 'tradeDrive', topic: "8.สามารถขับรถยนต์ได้", select: null, },
    { name: 'tradeMotorcise', topic: "9.สามารถขับรถมอเตอร์ไซค์ได้", select: null, },
    { name: 'tradeSit', topic: "10.สามารถนั่งหรือยืนเพื่อขายของต่อเนื่องนาน 2 ชั่วโมงขึ้นไปได้", select: null, },
    { name: 'tradeHeavy', topic: "11.สามารถเข็นของหนักได้ เช่น รถเข็น", select: null, },
    { name: 'tradeWall', topic: "12.สามารถล้างและขัดถูพื้นได้", select: null, },
    { name: 'tradeCal', topic: "13.สามารถคำนวณเงิน รับเงิน ทอนเงินได้", select: null, },
    { name: 'tradeMobile', topic: "14.สามารถสื่อสารทางโทรศัพท์ได้", select: null, },
    { name: 'tradeApp', topic: "15.สามารถใช้แอปพลิเคชันในมือถือได้ เช่น ถุงเงิน คนละครึ่ง เป๋าตัง เรารักกัน เราชนะ ม.33", select: null, },
]

export const Orcards = [
    { name: 'orcardKnife', topic: "1.สามารถลับมีด เตรียมหรือซ่อมแซมอุปกรณ์ในการทำสวนได้", select: null, },
    { name: 'orcardMachine', topic: "2.สามารถใช้<b>เครื่องมือ</b>ตัดแต่งกิ่งได้ เช่น มีด กรรไกรตัดแต่งกิ่ง กรรไกรตัดปลายไม้", select: null, },
    { name: 'orcardClimb', topic: "3.สามารถปีน<b>บันไดสูง</b>เพื่อตัดแต่งกิ่งได้", select: null, },
    { name: 'orcardWater', topic: "4.สามารถใช้<b>อุปกรณ์รดน้ำ</b>ได้ เช่น ฝักบัว สายยาง", select: null, },
    { name: 'orcardKeep', topic: "5.สามารถใช้อุปกรณ์เก็บผลไม้ ใน<b>ระดับสูงไม่เกินหัวไหล่</b>ได้", select: null, },
    { name: 'orcardShoulder', topic: "6.สามารถใช้อุปกรณ์เก็บผลไม้ ใน<b>ระดับสูงเกินหัวไหล่</b>ได้", select: null, },
    { name: 'orcardBasket', topic: "7.สามารถเก็บ<b>ผลไม้</b>ใส่ตะกร้าได้", select: null, },
    { name: 'orcardSale', topic: "8.สามารถยกตะกร้าผลไม้ขึ้น-ลงรถ เพื่อนำไปขายได้", select: null, },
    { name: 'orcardDrive', topic: "9.สามารถขับรถยนต์ได้", select: null, },
    { name: 'orcardMotor', topic: "10.สามารถขับมอเตอร์ไซค์ได้", select: null, },
    { name: 'orcardCal', topic: "11.สามารถคำนวณเงิน รับเงิน ทอนเงินได้", select: null, },
    { name: 'orcardMobile', topic: "12.สามารถสื่อสารทางโทรศัพท์ได้", select: null, },
    { name: 'orcard6hrm', topic: "13.สามารถทำงานในไร่ได้ต่อเนื่องอย่างน้อย 6 ชั่วโมงต่อวันได้", select: null, },
]

export const Rubbers = [
    { name: 'rubberKnife', topic: "1.สามารถลับมีดได้", select: null, },
    { name: 'rubberWalk', topic: "2.สามารถเดินในที่มืด หรือที่ที่มีแสงสว่างน้อยได้", select: null, },
    { name: 'rubberDrive', topic: "3.สามารถขับรถยนต์ได้", select: null, },
    { name: 'rubberMotor', topic: "4.สามารถขับมอเตอร์ไซค์ได้", select: null, },
    { name: 'rubberSquat', topic: "5.สามารถกรีดยางใน<b>ท่านั่งยอง</b>ได้", select: null, },
    { name: 'rubberBow', topic: "6.สามารถก้มกรีดยางในระดับหน้ายาง<b>ต่ำกว่าเอว</b>ได้", select: null, },
    { name: 'rubberStand', topic: "7.สามารถยืนกรีดยางในระดับหน้ายางที่<b>ไม่ต้องก้ม</b>ได้", select: null, },
    { name: 'rubberHeight', topic: "8.สามารถยืนกรีดยางในระดับหน้ายาง<b>สูงกว่าศีรษะ</b>ได้", select: null, },
    { name: 'rubberHand', topic: "9.สามารถกรีดยางโดยใช้วิธีกระตุกข้อมือ หรือ การซอยได้", select: null, },
    { name: 'rubberKeep', topic: "10.สามารถเก็บน้ำยางจากต้นใส่แกลลอนได้", select: null, },
    { name: 'rubberSale', topic: "11.สามารถยกแกลลอนน้ำยางขึ้นรถเพื่อนำไปขายได้", select: null, },
    { name: 'rubberCal', topic: "12.สามารถคำนวณเงิน รับเงิน ทอนเงินได้", select: null, },
    { name: 'rubberMobile', topic: "13.สามารถสื่อสารทางโทรศัพท์ได้", select: null, },
    { name: 'rubber6hrm', topic: "14.สามารถทำงานในสวนยาง อย่างน้อย 6 ชั่วโมงต่อวันได้", select: null, },
]

export const Farms = [
    { name: 'farmPrepare', topic: "1.สามารถเตรียมแปลงได้ เช่น ขับรถไถ/ใช้รถไถได้ด้วยตนเอง", select: null, },
    { name: 'farmHand', topic: "2.สามารถใช้<b>มือ</b>ถอนวัชพืช (หญ้า) ได้", select: null, },
    { name: 'farmMachine', topic: "3.สามารถใช้<b>เครื่องมือ</b>เพื่อกำจัดวัชพืช (หญ้า)ได้ เช่น มีด พร้า เสียม เคียว จอบ เครื่องตัดหญ้า", select: null, },
    { name: 'farmKnife', topic: "4.สามารถลับมีด หรือซ่อมแซมเครื่องมือในการเพาะปลูกได้", select: null, },
    { name: 'farmBack', topic: "5.สามารถก้มหลังหยอดเมล็ดพันธุ์ หรือปักดำต้นกล้าได้", select: null, },
    { name: 'farmWater', topic: "6.สามารถใช้<b>อุปกรณ์รดน้ำ</b>ได้ เช่น ฝักบัว สายยาง", select: null, },
    { name: 'farmProan', topic: "7.สามารถใช้<b>เครื่องมือ</b>พรวนดินได้ เช่น จอบ เสียม", select: null, },
    { name: 'farmWan', topic: "8.สามารถฝังหรือหว่านปุ๋ยเองได้", select: null, },
    { name: 'farmInjection', topic: "9.สามารถใช้<b>อุปกรณ์ฉีดปุ๋ยหรือยาฆ่าแมลง</b>ได้ เช่น อุปกรณ์สะพายหลัง อุปกรณ์แบบรถเข็น", select: null, },
    { name: 'farmKeep', topic: "10.สามารถใช้<b>มือเก็บ</b>เกี่ยวผลผลิตได้", select: null, },
    { name: 'farmTool', topic: "11.สามารถใช้<b>เครื่องมือ</b>เก็บเกี่ยวผลผลิตได้", select: null, },
    { name: 'farmPack', topic: "12.สามารถบรรจุผลผลิตได้ เช่น ใส่ถุงหรือใส่กระสอบ", select: null, },
    { name: 'farmDrive', topic: "13.สามารถขับรถยนต์ได้", select: null, },
    { name: 'farmMotorcise', topic: "14.สามารถขับมอเตอร์ไซค์ได้", select: null, },
    { name: 'farmCal', topic: "15.สามารถคำนวณเงิน รับเงิน ทอนเงินได้", select: null, },
    { name: 'farmMobile', topic: "16.สามารถสื่อสารทางโทรศัพท์ได้", select: null, },
    { name: 'farm6hrm', topic: "17.สามารถทำงานในไร่/สวนได้ต่อเนื่องอย่างน้อย 6 ชั่วโมงต่อวัน", select: null, },
]

export const Rices = [
    { name: 'ricePrepare', topic: "1.สามารถเตรียมแปลงนาได้ เช่น ขับรถไถ/ใช้รถไถได้ด้วยตนเอง", select: null, },
    { name: 'riceHand', topic: "2.สามารถใช้<b>มือ</b>ถอนวัชพืช (หญ้า)ได้", select: null, },
    { name: 'riceMachine', topic: "3.สามารถใช้<b>เครื่องมือ</b>เพื่อกำจัดวัชพืช (หญ้า)ได้ เช่น มีด พร้า เสียม เคียว จอบ เครื่องตัดหญ้า", select: null, },
    { name: 'riceWan', topic: "4.สามารถ<b>หว่าน<b>ปุ๋ยเองได้", select: null, },
    { name: 'riceInjection', topic: "5.สามารถใช้<b>อุปกรณ์ฉีดปุ๋ยหรือยาฆ่าแมลง</b>ได้", select: null, },
    { name: 'riceWater', topic: "6.สามารถใช้<b>อุปกรณ์รดน้ำ</b>ได้ เช่น สายยางสูบน้ำ", select: null, },
    { name: 'riceDig', topic: "7.สามารถใช้<b>เครื่องมือ</b>ในการขุดดินได้ เช่น จอบ เสียม ฯลฯ", select: null, },
    { name: 'riceKeep', topic: "8.สามารถใช้<b>เครื่องมือ</b>เก็บเกี่ยวผลผลิตได้", select: null, },
    { name: 'riceMotor', topic: "9.สามารถ<b>ขับรถเกี่ยวข้าว</b>ได้", select: null, },
    { name: 'riceSale', topic: "10.สามารถยกกระสอบข้าวขึ้น-ลงรถ เพื่อนำไปขายได้", select: null, },
    { name: 'riceDrive', topic: "11.สามารถขับรถยนต์ได้", select: null, },
    { name: 'riceMotorcise', topic: "12.สามารถขับมอเตอร์ไซค์ได้", select: null, },
    { name: 'riceMobile', topic: "13.สามารถสื่อสารทางโทรศัพท์ได้", select: null, },
    { name: 'rice6hrm', topic: "14.สามารถทำงานในไร่ได้ต่อเนื่องอย่างน้อย 6 ชั่วโมงต่อวันได้", select: null, },
]

export const Nannys = [
    { "name": "nannyLift15kg", "topic": "1.สามารถอุ้มเด็กที่มีน้ำหนักตัวมากกว่า 15 กิโลกรัมได้", "select": null },
    { "name": "nannyBath", "topic": "2.สามารถอาบน้ำ/เช็ดตัว ทำความสะอาดตัวเด็กได้", "select": null },
    { "name": "nannyDiasper", "topic": "3.สามารถเปลี่ยนผ้าอ้อม/เสื้อผ้าให้เด็กได้", "select": null },
    { "name": "nannyWashing", "topic": "4.สามารถซักผ้าได้ เช่น ผ้าอ้อม ผ้ากันเปื้อน ผ้าเช็ดมือ ผ้าเช็ดโต๊ะ เสื้อผ้า ได้", "select": null },
    { "name": "nannyCleaning", "topic": "5.สามารถล้าง ต้ม/นึ่งภาชนะหรือวัตถุได้ เช่น ถ้วยอาหาร แก้วน้ำ ขวดนม ได้", "select": null },
    { "name": "nannyMakemilk", "topic": "6.สามารถชงนม ให้นมเด็กอ่อนได้", "select": null },
    { "name": "nannyFeeding", "topic": "7.สามารถเตรียมอาหารและป้อนอาหารเด็กได้", "select": null },
    { "name": "nannyHousecleansing", "topic": "8.สามารถใช้งานแขนออกแรงทำงานในท่าเดิมซ้ำๆ เช่น ขัดล้างห้องน้ำ กวาด/ถูพื้น/ทำความสะอาดพื้นที่ได้", "select": null },
    { "name": "nannyPushingstroller", "topic": "9.สามารถเข็นรถเข็นเด็กได้", "select": null },
    { "name": "nannySit", "topic": "10.สามารถนั่งบนพื้นหรือเก้าอี้เตี้ย เพื่อดูแลเด็กระหว่างทำกิจกรรมได้", "select": null },
    { "name": "nannyOutdoor", "topic": "11.สามารถทำกิจกรรมกลางแจ้งร่วมกับเด็กได้", "select": null },
    { "name": "nannyObservation", "topic": "12.สามารถสังเกตพฤติกรรมที่ผิดปกติเมื่อเด็กไม่สบาย วัดอุณหภูมิ เช็ดตัวลดไข้ ได้", "select": null },
    { "name": "nannyLullaby", "topic": "13.สามารถกล่อมเด็กนอนได้ เช่น เล่านิทาน ร้องเพลง ได้", "select": null },
    { "name": "nannyProtection", "topic": "14.สามารถจับเด็กที่กำลังจะล้มได้ทันได้", "select": null }
  ]

export const Salepromotions = [
    { "name": "salepromotionLifting", "topic": "1.สามารถใช้สองมือในการยกของได้ เช่น ยกผลแตงโม", "select": null },
    { "name": "salepromotionPickorange", "topic": "2.สามารถใช้มือเดียวหยิบของที่มีขนาดเท่าผลส้มได้", "select": null },
    { "name": "salepromotionPickbean", "topic": "3.สามารถใช้มือเดียวหยิบของที่มีขนาดเท่าเมล็ดถั่วเขียวได้", "select": null },
    { "name": "salepromotionPickhighting", "topic": "4.สามารถเอื้อมมือหยิบของบนชั้นวางของสูงเกินระดับหัวไหล่ได้", "select": null },
    { "name": "salepromotionOpentightbottle", "topic": "5.สามารถเปิดฝาขวดที่ปิดแน่น บิดลูกบิดประตู หรือ บิดลูกกุญแจเพื่อปลดล็อคแม่กุญแจ ได้", "select": null },
    { "name": "salepromotionCarrybag", "topic": "6.สามารถหิ้วถุงใส่ของหรือหิ้วกระเป๋าได้", "select": null },
    { "name": "salepromotionFillingbox", "topic": "7.สามารถบรรจุผลผลิต เช่น ใส่ถุง ใส่กล่องได้", "select": null },
    { "name": "salepromotionUsingapp", "topic": "8.สามารถใช้คอมพิวเตอร์ หรือแอปพลิเคชันในมือถือ เช่น ตรวจสอบรายการสั่งซื้อ พิมพ์โต้ตอบโอนเงินผ่านการสแกน QR code ได้", "select": null },
    { "name": "salepromotionUsingcom30m", "topic": "9.สามารถนั่งทำงานหน้าคอมพิวเตอร์อย่างต่อเนื่อง ไม่หยุดพัก ได้นาน 30 นาทีขึ้นไปได้", "select": null },
    { "name": "salepromotionStand30m", "topic": "10.สามารถยืนทำงานขายของหน้าร้านอย่างต่อเนื่องโดยไม่ต้องนั่งพัก ได้นาน 30 นาทีขึ้นไปได้", "select": null },
    { "name": "salepromotionCom3hrneck", "topic": "11.สามารถนั่งทำงานคอมพิวเตอร์วันละ 3 ชั่วโมงหรือมากกว่า โดยไม่มีอาการปวดหรือชาบริเวณ คอ บ่า สะบัก แขน มือ ปลายนิ้ว หลังส่วนบน และบั้นเอวได้", "select": null },
    { "name": "salepromotionCom3hrfinger", "topic": "12.สามารถนั่งทำงานคอมพิวเตอร์วันละ 3 ชั่วโมงหรือมากกว่า โดยไม่มีอาการนิ้วล็อก หรือ ข้อต่อ/กล้ามเนื้อยึดตึงบริเวณข้อไหล่ ข้อศอก หรือข้อมือได้", "select": null },
    { "name": "salepromotionCom3hrhead", "topic": "13.สามารถนั่งทำงานคอมพิวเตอร์วันละ 3 ชั่วโมงหรือมากกว่า โดยไม่มีอาการปวดศีรษะ มึนงง ตาพร่า ได้", "select": null },
    { "name": "salepromotionStand3hrback", "topic": "14.ยืน/เดิน แนะนำสินค้าภายในร้านวันละ 3 ชั่วโมงหรือมากกว่า โดยไม่มีอาการปวดหรือชา บริเวณ หลัง/สะโพก/เข่า/ขา/เท้า ได้", "select": null },
    { "name": "salepromotionStand3hrleg", "topic": "15.ยืน/เดิน แนะนำสินค้าภายในร้านวันละ 3 ชั่วโมงหรือมากกว่า โดยไม่มีอาการเดินเซ กล้ามเนื้อขาอ่อนแรง หรือ ขา/เท้าบวม ได้", "select": null },
    { "name": "salepromotionCal", "topic": "16.สามารถคำนวณเงิน รับเงิน ทอนเงิน ได้", "select": null },
    { "name": "salepromotionSmartphone", "topic": "17.สามารถสื่อสารทางโทรศัพท์ได้", "select": null }
  ]
