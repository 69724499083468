const getDeviceName = () => {

}

const activity = (axios) => {
    return {
        state: {
            activities: []
        },
        mutations: {
            setActivities(state, payload) {
                state.activities = payload
            }
        },
        actions: {
            /* async getUserAgentIp() {
                 const response = await axios.get(`https://api.ipify.org`, {
                     params: { format: 'json' }
                 }).then(http => http.data)
                 return new Promise(resolve => {
                     const { data } = response
                     // console.log(`data ::== ${JSON.stringify(data)}`)
                     resolve(response)
                 })
             },*/
            async fetchActivities({ commit, rootGetters }, payload) {
                try {

                    /*
                    userId , sort 
                    */
                    const response = await axios.get(`/master/activity/log/all/order/activityDtm`, {
                        params: payload
                    }).then(http => http.data)
                    return new Promise(resolve => {
                        const { data } = response
                        // console.log(`data ::== ${JSON.stringify(data)}`)
                        commit('setActivities', data);
                        resolve(response)
                    })
                } catch (error) {
                    return Promise.reject(error);
                }
            },
            async saveActionActivityLog({ dispatch, rootGetters }, payload) {
                try {
                    const { codeId: userId, userLevel, userName, area } = rootGetters[
                        'oauth/session'
                    ]
                    //const { ip } = await dispatch('getUserAgentIp');
                    console.log('userId ::==', userId, '   userLevel :: ==', userLevel, '  area::== ', area)
                    const response = await axios.post(`/master/activity/log/save`, {
                        ...payload,
                        user: { codeId: userId },
                        //  actIp: ip
                    }).then(http => http.data)
                    return new Promise(resolve => {
                        resolve(response)
                    })
                } catch (error) {
                    return Promise.reject(error);
                }
            }
        },
        namespaced: true,
    }
}

export default activity;